import * as React from "react";
import { graphql, Link } from "gatsby";
import { Box, Heading, VStack, Text, Button } from "@chakra-ui/react";
import Helmet from "react-helmet";
import Navbar from "../../components/Navbar";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
const blogIndex = ({ data }) => {
  const posts = data.allMdx.nodes;
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta name="description" content="The blog for Akaash Sinha" />
        <title>Akaash Sinha | Blog</title>
      </Helmet>
      <Navbar />
      <Heading textAlign="center">My Blog</Heading>
      {posts.map((post) => {
        const title = post.frontmatter.title || post.fields.slug;
        const image = getImage(post.frontmatter?.embeddedImagesLocal[0]);
        return (
          <div className="" key={post.id}>
            <VStack py={5}>
              <Box
                p={5}
                borderWidth={1}
                borderRadius="lg"
                shadow="md"
                maxW={"33%"}
              >
                <Heading textAlign={"center"} _hover={{ color: "blue.600" }}>
                  <Link to={`/blog/${post.slug}`}>{title}</Link>
                </Heading>
                <Box>
                  <Text fontSize="xs" textAlign={"center"}>
                    {post.frontmatter.date}
                  </Text>
                </Box>
                <Box py={2} textAlign={"center"}>
                  <GatsbyImage image={image} alt={post.frontmatter.title} />
                </Box>
                <Text textAlign={"center"}>{post.excerpt}</Text>
                <Box pt={5} textAlign="center">
                  <Link to={`/blog/${post.slug}`}>
                    <Button
                      aria-label={`Read More: ${title}`}
                      colorScheme="green"
                    >
                      Read More
                    </Button>
                  </Link>
                </Box>
              </Box>
            </VStack>
          </div>
        );
      })}
    </>
  );
};

export default blogIndex;

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "Do-MMMM-YYYY")
          title
          embeddedImagesLocal {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 300)
            }
          }
        }
        excerpt
        slug
        id
      }
    }
  }
`;
